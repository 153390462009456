import React from 'react'

const Rules = () => {
    return (
        <div className="content">
            <section className="section">
                <h2 className="section__title">Публичная оферта</h2>
                <p>
                    Покупатель (Потребитель) – физическое лицо, имеющее намерение зарезервировать и/или приобрести товары, либо резервирующее, приобретающее или использующее товары исключительно для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности, разместившее Заказ на Сайте TechStore, либо указанное в Заказе лицо в качестве получателя Товара.
                </p>
                <p>
                    Дистанционный способ продажи – продажа товаров по договору розничной купли-продажи, заключаемому на основании ознакомления Покупателя с предложенным Продавцом описанием товара, содержащимся на Сайте TechStore, при этом должна быть исключена возможность непосредственного ознакомления Покупателя с Товаром либо образцом товара при заключении такого договора.
                </p>
                <p>
                    Товар – объект купли-продажи, не изъятый и не ограниченный в гражданском обороте, представленный к продаже или доступный для резервирования в интернет-магазине «TechStore», посредством размещения в соответствующем разделе интернет-магазина «TechStore» в качестве образца для ознакомления.
                </p>
                <p>
                    Публичная оферта – публичное предложение Продавца, адресованное неопределенному кругу лиц, заключить с Продавцом договор купли-продажи товара дистанционным способом.
                </p>
                <p>
                    Заказ – оформленный по форме Продавца запрос Покупателя на резервирование Товаров интернет-магазина «TechStore» с возможностью их доставки по указанному в запросе адресу для целей заключения договора купли-продажи, и отправленный Покупателем на сайт или посредством телефонной связи с Оператором Продавца.
                </p>
            </section>
        </div>
    )
}

export default Rules
